/*
 ** Core logic from https://github.com/component/escape-html
 ** Many thanks to @component
 */

const matchHtmlRegExp = /["'&<>]/;

export const escapeHTML = (string?: string | null): string => {
	const str = "" + string;
	const match = matchHtmlRegExp.exec(str);

	if (!match) {
		return str;
	}

	let escape;
	let html = "";
	let index = 0;
	let lastIndex = 0;

	for (index = match.index; index < str.length; index++) {
		switch (str.charCodeAt(index)) {
			case 34: // "
				escape = "&quot;";
				break;
			case 38: // &
				escape = "&amp;";
				break;
			case 39: // '
				escape = "&#39;";
				break;
			case 60: // <
				escape = "&lt;";
				break;
			case 62: // >
				escape = "&gt;";
				break;
			default:
				continue;
		}

		if (lastIndex !== index) {
			html += str.substring(lastIndex, index);
		}

		lastIndex = index + 1;
		html += escape;
	}

	return lastIndex !== index ? html + str.substring(lastIndex, index) : html;
};
